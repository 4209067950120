










import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "List",
  components: {
    ListGroup: () => import("./ListGroup.vue"),
    ListItem: () => import("./ListItem.vue"),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
